import {
  DisplayCurrency,
  DisplayDate,
  Image,
  ProjectBadge,
  Title,
  Tooltip
} from '@jeromevvb/equinox-lib'
import Round from '@models/projects/enums/round.enum'
import Project from '@models/projects/interfaces/project.interface'
import NextLink from 'next/link'
import styles from './styles'

interface LaunchedProjectProps {
  project: Project
  isLoading: boolean
}

const LaunchedProject = ({ project, isLoading }: LaunchedProjectProps) => {
  const { slug, media, name, totalRaise, poolOpenDate, round, guild } = project

  // if (isLoading) {
  //   return (
  //     <div css={styles}>
  //       <div className="project" >
  //         <div className="project-title">
  //           <div className="project-image skeleton-image" />
  //           <span className="skeleton-text w-48" />
  //         </div>
  //         <span className="skeleton-text w-16 mx-auto" />
  //         <span className="skeleton-text w-16 mx-auto" />
  //         <span className="skeleton-text w-16 mx-auto" />
  //         <span className="skeleton-text w-16 mx-auto" />
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <NextLink passHref href={`/project/${slug}`}>
      <a>
        <div css={styles} data-testid="launched-project">
          <div className="project">
            <div className="project-title">
              <Image
                src={media.logo.url}
                alt={name}
                className="project-image"
              />
              <div className="project-name sm:flex items-center">
                <Title size="small">{name}</Title>
                <div className="project-badges">
                  {guild && (
                    <ProjectBadge
                      className="project-badge"
                      color="primary"
                      size="small"
                    >
                      <Tooltip
                        label="Join the guild to play this game"
                        placement="top"
                      >
                        Guild
                      </Tooltip>
                    </ProjectBadge>
                  )}
                  {round === Round.PRIVATE && (
                    <ProjectBadge className="project-badge" size="small">
                      <Tooltip
                        label="Private sale. Early stage investment round"
                        placement="top"
                      >
                        Private
                      </Tooltip>
                    </ProjectBadge>
                  )}
                </div>
              </div>
            </div>
            <DisplayCurrency
              amount={totalRaise}
              TypographyProps={{
                size: 'small',
                className: 'text-center'
              }}
            />
            <DisplayDate
              date={poolOpenDate}
              TypographyProps={{
                size: 'small',
                className: 'text-center'
              }}
            />
          </div>
        </div>
      </a>
    </NextLink>
  )
}

export default LaunchedProject
