import {
  Algorand,
  BinanceSmartChain,
  Ethereum,
  Polygon,
  Solana
} from '@jeromevvb/equinox-lib'

import Network from '@models/projects/enums/network.enum'
import ProjectNetworkType from '@models/projects/enums/projectNetwork.enum'
import classnames from 'classnames'
import { FC } from 'react'

interface ProjectNetworkProps {
  network: Network | ProjectNetworkType
  className?: string
}

const ProjectNetwork: FC<ProjectNetworkProps> = ({ network, className }) => {
  if (network === Network.BSC) {
    return <BinanceSmartChain className={classnames('w-5', className)} />
  }

  if (network === Network.ETH) {
    return <Ethereum className={classnames('w-5', className)} />
  }

  if (network === Network.POLYGON) {
    return <Polygon className={classnames('w-5', className)} />
  }

  if (network === ProjectNetworkType.SOLANA) {
    return <Solana className={classnames('w-5', className)} />
  }

  if (network === ProjectNetworkType.ALGORAND) {
    return <Algorand className={classnames('w-5', className)} />
  }

  return null
}

export default ProjectNetwork
