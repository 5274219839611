import tw, { css } from 'twin.macro'

const styles = () => css`
  ${tw`bg-primary`}
  background: linear-gradient(94.68deg, #6560BD -5.91%, #69C0CA 118.07%);
  ${tw`py-6`}
  ${tw`px-4`}
  ${tw`flex`}
  ${tw`flex-col`}
  ${tw`items-center`}
  ${tw`justify-center`}
  ${tw`rounded-md`}
  overflow: hidden;

  ${tw`xxs:px-6`}
  ${tw`lg:space-x-8`}
  ${tw`lg:flex-row`}

  .text {
    ${tw`flex`}
    ${tw`flex-wrap`}
    ${tw`flex-col`}
    ${tw`text-center`}
    ${tw`mb-4`}
    
    ${tw`lg:mb-0`}
    ${tw`lg:text-left`}
    ${tw`xl:space-x-2`}
    ${tw`xl:flex-row`}
  }
`

export default styles
