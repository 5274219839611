import React from 'react'
import { Typography, TypographyProps } from '@jeromevvb/equinox-lib'
import styles from './styles'
import classnames from 'classnames'

export interface StatusIndicatorProps {
  variant: 'active' | 'upcoming'
  label?: string
  size?: 'small' | 'medium'
  TypographyProps?: TypographyProps
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  label,
  variant,
  size = 'medium',
  TypographyProps
}) => {
  return (
    <div css={styles({ size })}>
      {label && <Typography {...TypographyProps}>{label}</Typography>}
      <div className={classnames('indicator', variant)} />
    </div>
  )
}

export default StatusIndicator
