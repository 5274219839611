import DisplayAthDifference from '@components/common/DisplayAthDifference'
import DisplayCurrentPrice from '@components/common/DisplayCurrentPrice'
import {
  DisplayCurrency,
  DisplayDate,
  Image,
  ProjectBadge,
  Title,
  Typography,
  Tooltip
} from '@jeromevvb/equinox-lib'
import getPriceMultiplier from '@helpers/getPriceMultiplier'
import Round from '@models/projects/enums/round.enum'
import SaleType from '@models/projects/enums/saleType.enum'
import Project from '@models/projects/interfaces/project.interface'
import NextLink from 'next/link'
import styles from './styles'

interface LaunchedProjectProps {
  project: Project
  isLoading: boolean
}

const LaunchedProject = ({ project, isLoading }: LaunchedProjectProps) => {
  const {
    slug,
    media,
    name,
    totalRaise,
    poolOpenDate,
    token,
    saleType,
    round,
    price = {
      current: 0,
      ath: 0
    },
    guild
  } = project

  const launchPrice = parseFloat(token?.price)
  const contractAddress = token?.contractAddress

  const { current, ath } = price
  const currentPriceMultiplier = getPriceMultiplier(launchPrice, current)
  const athMultipler = getPriceMultiplier(launchPrice, ath)

  if (isLoading) {
    return (
      <div css={styles}>
        <div className="project">
          <div className="project-title">
            <div className="project-image skeleton-image" />
            <span className="skeleton-text w-48" />
          </div>
          <span className="skeleton-text w-16 mx-auto" />
          <span className="skeleton-text w-16 mx-auto" />
          <span className="skeleton-text w-16 mx-auto" />
        </div>
      </div>
    )
  }

  return (
    <NextLink passHref href={`/project/${slug}`}>
      <a>
        <div css={styles} data-testid="launched-project">
          <div className="project">
            <div className="project-title">
              <Image
                src={media.logo.url}
                alt={name}
                className="project-image"
              />
              <div className="project-name sm:flex items-center">
                <Title size="small">{name}</Title>
                <div className="project-badges">
                  {guild && (
                    <ProjectBadge
                      className="project-badge"
                      color="primary"
                      size="small"
                    >
                      <Tooltip
                        label="Join the guild to play this game"
                        placement="top"
                      >
                        Guild
                      </Tooltip>
                    </ProjectBadge>
                  )}
                  {round === Round.PRIVATE && (
                    <ProjectBadge className="project-badge" size="small">
                      <Tooltip
                        label="Private sale. Early stage investment round"
                        placement="top"
                      >
                        Private
                      </Tooltip>
                    </ProjectBadge>
                  )}
                </div>
              </div>
            </div>
            {contractAddress && price ? (
              <>
                <div className="flexed">
                  <DisplayAthDifference
                    project={project}
                    TypographyProps={{
                      size: 'small',
                      className: 'text-center'
                    }}
                  />
                  {athMultipler > 0 && (
                    <Typography
                      color="muted"
                      size="large"
                      className="mx-1"
                      caption
                    >
                      ({athMultipler}x)
                    </Typography>
                  )}
                </div>
                {/* <div className="flexed">
                <DisplayCurrentPrice
                  project={project}
                  TypographyProps={{
                    size: 'small',
                    className: 'text-center'
                  }}
                />
                {currentPriceMultiplier > 0 && (
                  <Typography
                    color="muted"
                    size="large"
                    caption
                    className="mx-1"
                  >
                    ({currentPriceMultiplier}x)
                  </Typography>
                )}
              </div> */}
              </>
            ) : (
              <>
                <Typography size="small" className="text-center">
                  NA
                </Typography>
                {/* <Typography size="small" className="text-center">
                NA
              </Typography> */}
              </>
            )}
            <DisplayCurrency
              amount={totalRaise}
              TypographyProps={{
                size: 'small',
                className: 'text-center'
              }}
            />
            <DisplayDate
              date={poolOpenDate}
              TypographyProps={{
                size: 'small',
                className: 'text-center'
              }}
            />
          </div>
        </div>
      </a>
    </NextLink>
  )
}

export default LaunchedProject
