import DisplayTokenSymbol from '@components/common/DisplayTokenSymbol'
import ProjectNetwork from '@components/common/ProjectNetwork'
import StatusIndicator from '@components/common/StatusIndicator'
import { isParticipationOpen } from '@contexts/ProjectParticipationContext/helpers'
import {
  Card,
  DisplayCurrency,
  DisplayDate,
  Image,
  ProjectBadge,
  Title,
  Tooltip,
  Typography,
  TypographyWithLabel
} from '@jeromevvb/equinox-lib'
import useTranslation from '@hooks/useTranslation'
import Round from '@models/projects/enums/round.enum'
import SaleType from '@models/projects/enums/saleType.enum'
import Status from '@models/projects/enums/status.enum'
import Project from '@models/projects/interfaces/project.interface'
import NextLink from 'next/link'
import { useMemo } from 'react'
import { BsCardImage } from 'react-icons/bs'
import styles from './styles'

export interface ProjectCardProps {
  project: Project
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const { t } = useTranslation('project')

  const {
    slug,
    name,
    poolOpenDate,
    participationCloseDate,
    participationOpenDate,
    totalRaise,
    network,
    token,
    media,
    saleType,
    round,
    guild,
    status
  } = project

  const isUpcoming = [Status.UPCOMING].includes(status)
  const isActive = [Status.ACTIVE].includes(status)
  const { logo, thumbnail } = media

  const _isParticipationOpen = useMemo(() => {
    return isParticipationOpen(participationOpenDate, participationCloseDate)
  }, [participationOpenDate, participationCloseDate])

  return (
    <div key={slug} css={styles} className="project">
      <Card padding={false} className="overflow-hidden" hoverAsLink>
        <NextLink passHref href={`/project/${slug}`}>
          <a>
            <div className="project-bg">
              <div className="project-bg-inner">
                {thumbnail ? (
                  <Image alt={name} src={thumbnail.url} />
                ) : (
                  <Typography color="muted" className="project-bg-coming-soon">
                    coming soon
                  </Typography>
                )}
              </div>
              <div className="project-badges">
                {guild && (
                  <ProjectBadge
                    className="project-badge"
                    color="primary"
                    size="small"
                  >
                    <Tooltip
                      label="Join the guild to play this game"
                      placement="top"
                    >
                      Guild
                    </Tooltip>
                  </ProjectBadge>
                )}
                {saleType === SaleType.INO && (
                  <ProjectBadge className="project-badge" size="small">
                    <Tooltip label="Initial NFT Offering" placement="top">
                      INO
                    </Tooltip>
                  </ProjectBadge>
                )}
                {saleType === SaleType.IDO && round !== Round.PRIVATE && (
                  <ProjectBadge className="project-badge" size="small">
                    <Tooltip label="Initial DEX Offering" placement="top">
                      IDO
                    </Tooltip>
                  </ProjectBadge>
                )}
                {round === Round.PRIVATE && (
                  <ProjectBadge className="project-badge" size="small">
                    <Tooltip
                      label="Private sale. Early stage investment round"
                      placement="top"
                    >
                      Private
                    </Tooltip>
                  </ProjectBadge>
                )}
              </div>
            </div>
            <div className="project-inner">
              <div className="project-header">
                <div className="project-logo">
                  {logo ? (
                    <Image alt={name} src={logo.url} />
                  ) : (
                    <BsCardImage
                      className="project-logo-placeholder"
                      size="32"
                    />
                  )}
                </div>
                <div>
                  <Title size="medium" weight="semibold">
                    {name}
                  </Title>
                  <div className="project-token">
                    <DisplayTokenSymbol
                      TypographyProps={{
                        color: 'muted'
                      }}
                      symbol={saleType === SaleType.INO ? 'NFT' : token?.symbol}
                    />
                  </div>
                </div>
                <div className="project-currency">
                  <ProjectNetwork network={network} />
                </div>
              </div>
              <div className="mb-8">
                <TypographyWithLabel label={t('card.totalRaise')}>
                  <DisplayCurrency amount={totalRaise} />
                </TypographyWithLabel>

                <TypographyWithLabel
                  label={isUpcoming ? t('card.launchDate') : t('card.launched')}
                >
                  <DisplayDate utc date={poolOpenDate} format="dateTime" />
                </TypographyWithLabel>

                <TypographyWithLabel
                  label="Sale type"
                  TypographyProps={{
                    size: 'medium'
                  }}
                >
                  {saleType === SaleType.INO ? 'NFT Sale' : 'Token Sale'}
                </TypographyWithLabel>

                {isUpcoming && _isParticipationOpen && (
                  <TypographyWithLabel label="Status">
                    <StatusIndicator
                      label="Participation open"
                      variant="active"
                    />
                  </TypographyWithLabel>
                )}
                {isActive && (
                  <TypographyWithLabel label="Status">
                    <StatusIndicator label="Launching now" variant="active" />
                  </TypographyWithLabel>
                )}
              </div>
            </div>
          </a>
        </NextLink>
      </Card>
    </div>
  )
}

export default ProjectCard
