import ProjectCard from '@components/project/components/ProjectCard'
import JoinCommunity from './JoinCommunity'
import { Title } from '@jeromevvb/equinox-lib'
import useProjects from '@contexts/ProjectsContext/hooks/useProjects'
import pluralize from 'pluralize'
import styles from './styles'

const ProjectsList = () => {
  const { projects } = useProjects()
  const { upcoming, active } = projects
  const mergedProjects = [...active, ...upcoming]

  if (mergedProjects.length === 0) return null

  const isTwoCol = mergedProjects.length <= 2

  const label = pluralize('Current project', mergedProjects.length)

  return (
    <div css={styles({ isTwoCol })}>
      <Title size="large" weight="semibold" className="mb-6 md:mb-10">
        {label}
      </Title>
      <div className="project-list">
        {mergedProjects.map((project) => (
          <ProjectCard key={project.slug} project={project} />
        ))}
        {mergedProjects.length === 1 && <JoinCommunity />}
      </div>
    </div>
  )
}

export default ProjectsList
