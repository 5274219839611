import useTranslation from '@hooks/useTranslation'
import React, { Fragment } from 'react'
import { Typography, TypographyProps } from '@jeromevvb/equinox-lib'

export interface DisplayTokenSymbolProps {
  symbol: string | string[]
  TypographyProps?: TypographyProps
}

const DisplayTokenSymbol: React.FC<DisplayTokenSymbolProps> = (props) => {
  const { symbol, TypographyProps } = props
  const { t } = useTranslation()

  if (!symbol) {
    return (
      <Typography uppercase {...TypographyProps}>
        {t('tbd')}
      </Typography>
    )
  }

  const _symbol = symbol.toString().split(',')

  if (_symbol.length === 1) {
    return (
      <Typography uppercase {...TypographyProps}>
        {_symbol[0]}
      </Typography>
    )
  }

  const elements = _symbol.map((content, i) => (
    <Fragment key={content}>
      <Typography uppercase {...TypographyProps}>
        {content}
        {_symbol.length > 1 && i + 1 < _symbol.length && ', '}
      </Typography>
    </Fragment>
  ))

  return <div className="flex space-x-1">{elements}</div>
}

export default DisplayTokenSymbol
