import { css } from '@emotion/react'
import tw from 'twin.macro'

const styles = ({ size }) => css`
  display: flex;
  align-items: center;

  .indicator {
    ${tw`rounded-full`}
    ${size === 'small' ? tw`w-2` : tw`w-3`}
    ${size === 'small' ? tw`h-2` : tw`h-3`}
    ${tw`ml-2`}
    ${tw`animate-pulse`}
    animation-duration: 1s;
  }

  .indicator.active {
    ${tw`bg-system-success`}
  }

  .indicator.upcoming {
    ${tw`bg-primary`}
  }
`

export default styles
