// Show how many x's between two prices
const getPriceMultiplier = (startPrice: number, endPrice: number) => {
  if (endPrice < startPrice) return 0

  const math =
    endPrice / (Array.isArray(startPrice) ? startPrice[0] : startPrice)

  const multiplier = Math.pow(10, 1)
  return Math.round(math * multiplier) / multiplier
}

export default getPriceMultiplier
