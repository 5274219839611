import tw, { css } from 'twin.macro'

const styles = () => css`
  .flexed {
    display: flex;
    ${tw`justify-center`}
    ${tw`items-center`}
  }

  .project-name {
    overflow: hidden;
    position: relative;

    h4 {
      ${tw`md:font-semibold`}
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      ${tw`mr-2`}
    }
  }

  .project-badges {
    ${tw`mt-0.5`}
    display: flex;

    ${tw`sm:mt-0`}
  }

  .project-badge {
    ${tw`inline-block`}
    ${tw`mr-1`}
  }

  .skeleton-image,
  .skeleton-text {
    ${tw`animate-pulse`}
  }

  .skeleton-image {
    ${tw`bg-neutral-charcoal`}
  }

  .skeleton-text {
    ${tw`bg-neutral-charcoal`}
    ${tw`h-5`}
    ${tw`rounded`}
  }
`

export default styles
