import tw, { css } from 'twin.macro'

const styles = ({ isTwoCol }) => css`
  .project-list {
    display: grid;
    ${tw`grid-cols-1`}
    ${tw`gap-6`}
    ${tw`mb-20`}

    ${tw`sm:grid-cols-2`}
    ${!isTwoCol && tw`lg:grid-cols-3`}
  }
`

export default styles
