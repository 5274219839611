import { Card, Heading, Button } from '@jeromevvb/equinox-lib'
import NextLink from 'next/link'
import styles from './styles'

const JoinCommunity = () => (
  <div css={styles}>
    <Card className="card">
      <div>
        <Heading size="small" className="copy">
          Don't miss out on the next project!
        </Heading>
        <NextLink href="/how-to-participate" passHref>
          <a>
            <Button size="large" color="secondary" className="mx-auto">
              How to participate
            </Button>
          </a>
        </NextLink>
      </div>
    </Card>
  </div>
)

export default JoinCommunity
