import {
  Checkbox,
  Divider,
  RoundedTabs,
  TextField,
  Title
} from '@jeromevvb/equinox-lib'
import SaleType from '@models/projects/enums/saleType.enum'
import Project from '@models/projects/interfaces/project.interface'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { IoIosSearch } from 'react-icons/io'
import LaunchedIDO from './LaunchedIDO'
import LaunchedINO from './LaunchedINO'
import styles from './styles'

interface LaunchedProjectsProps {
  projects: Array<Project>
}

const tabs = [
  {
    label: 'IDO',
    value: SaleType.IDO
  },
  {
    label: 'INO',
    value: SaleType.INO
  }
]

const LaunchedProjects: React.FC<LaunchedProjectsProps> = ({ projects }) => {
  const router = useRouter()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [onlyVested, setOnlyVested] = useState<boolean>(false)

  const queryFilter = 'filter'
  const activeTab = router?.query[queryFilter] || SaleType.IDO

  const handleClickTab = (tabValue) => {
    router.push(
      { pathname: window.location.origin, query: { [queryFilter]: tabValue } },
      null,
      {
        scroll: false
      }
    )
  }

  const projectsIDO = useMemo(() => {
    return projects.filter((p) => p.saleType === SaleType.IDO)
  }, [projects])

  const projectsINO = useMemo(() => {
    return projects.filter((p) => p.saleType === SaleType.INO)
  }, [projects])

  return (
    <div css={styles}>
      <div className="header">
        <Title size="large" weight="semibold">
          Launched projects
        </Title>
      </div>
      <Divider horizontal className="my-8"></Divider>
      <div className="filters">
        <div className="flex items-center mb-4 sm:mb-8">
          <div>
            <RoundedTabs
              onClickTab={handleClickTab}
              tabs={tabs}
              activeTab={activeTab}
            />
          </div>
          {activeTab === SaleType.IDO && (
            <>
              <div className="xs:hidden">
                <Checkbox
                  label="Vested"
                  onChange={(e) => setOnlyVested(e.currentTarget.checked)}
                  TypographyProps={{ size: 'medium' }}
                />
              </div>
              <div className="hidden xs:block">
                <Checkbox
                  label="Only vested tokens"
                  onChange={(e) => setOnlyVested(e.currentTarget.checked)}
                  TypographyProps={{ size: 'medium' }}
                />
              </div>
            </>
          )}
        </div>
        <div className="search">
          <TextField
            placeholder="Search by project"
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            icon={<IoIosSearch size="28" />}
            size="medium"
            borders={false}
          />
        </div>
      </div>

      {activeTab === SaleType.IDO && (
        <LaunchedIDO
          projects={projectsIDO}
          itemsPerPage={10}
          searchQuery={searchQuery}
          onlyVested={onlyVested}
        />
      )}
      {activeTab === SaleType.INO && (
        <LaunchedINO
          projects={projectsINO}
          itemsPerPage={10}
          searchQuery={searchQuery}
        />
      )}
    </div>
  )
}

export default LaunchedProjects
