import getPriceMultiplier from '@helpers/getPriceMultiplier'
import Project from '@models/projects/interfaces/project.interface'
import React from 'react'
import { Typography, TypographyProps } from '@jeromevvb/equinox-lib'

export interface DisplayAthDifferenceProps {
  project: Project
  onFetchSuccess?: (ath: number) => void
  TypographyProps?: TypographyProps
}

const DisplayAthDifference: React.FC<DisplayAthDifferenceProps> = ({
  project,
  TypographyProps = {}
}) => {
  const { token, price } = project
  const { contractAddress } = token
  const launchPrice = parseFloat(token?.price)
  const athPriceMulitplier = getPriceMultiplier(launchPrice, price?.ath)

  if (!contractAddress || !price?.ath) {
    return (
      <Typography weight="medium" className="text-center">
        NA
      </Typography>
    )
  }

  const isPositive = price?.ath > 0
  const diffAsPercentage = Math.round(athPriceMulitplier * 100)

  return (
    <Typography
      {...TypographyProps}
      color={isPositive ? 'success' : 'primary'}
      className="mx-1"
    >
      {isPositive ? '+' : '-'}
      {diffAsPercentage}%
    </Typography>
  )
}

export default DisplayAthDifference
