import Head from 'next/head'
import { Fragment } from 'react'

export interface PageProps {
  title: string
  description?: string
  image?: string
  children?: any
  robots?: string
}

const Page: React.FC<PageProps> = (props) => {
  const {
    children,
    description,
    image,
    title,
    robots = 'index, follow'
  } = props

  const metaDescription = description
  const metaImage = image || 'https://static.equinox.fund/meta/og-image.jpg'
  const metaTitle = title ? `${title} - Equinox` : 'Equinox - NOX Ecosystem'

  return (
    <Fragment>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="og:title" content={metaTitle} />
        <meta name="og:description" content={metaDescription} />
        <meta name="og:image" content={metaImage} />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@NOX_Ecosystem" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:image" content={metaImage} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="robots" content={robots} />
      </Head>
      {children}
    </Fragment>
  )
}

export default Page
