import { Typography, ArrowRight } from '@jeromevvb/equinox-lib'
import styles from './styles'
import classnames from 'classnames'

interface ColumnHeaderButtonProps {
  active: boolean
  ascending: boolean
  onClick?: () => void
}

const ColumnHeaderButton: React.FC<ColumnHeaderButtonProps> = ({
  active,
  ascending,
  onClick,
  children
}) => {
  const classname = classnames('col-header', {
    active,
    ascending
  })
  return (
    <button css={styles} className={classname} onClick={onClick}>
      <Typography size="small">{children}</Typography>
      <ArrowRight className="col-header-chevron" />
    </button>
  )
}

export default ColumnHeaderButton
