import tw, { css } from 'twin.macro'

const styles = () => css`
  ${tw`grid`}
  ${tw`grid-cols-4`}
  ${tw`gap-x-4`}
  ${tw`mb-2`}
  ${tw`text-center`}
  ${tw`mr-4`}
  ${tw`bg-neutral-charcoal`}
  ${tw`py-4`}
  ${tw`sm:grid-cols-5`}
  ${tw`md:mr-0`}

  button.col-header:first-of-type {
    ${tw`col-start-2`}
    ${tw`sm:col-start-3`}
  }

  .col-header {
    position: relative;
    display: flex;
    ${tw`items-center`}
    ${tw`justify-center`}

    p {
      display: inline-block;
      ${tw`text-body-sm`}
      ${tw`text-secondary`}
    }
  }
`

export default styles
