import DisplayTokenSymbol from '@components/common/DisplayTokenSymbol'
import ProjectNetwork from '@components/common/ProjectNetwork'
import StatusIndicator from '@components/common/StatusIndicator'
import { isParticipationOpen } from '@contexts/ProjectParticipationContext/helpers'
import {
  Button,
  Card,
  DisplayCurrency,
  DisplayDate,
  Divider,
  Image,
  ProjectBadge,
  Title,
  Tooltip,
  Typography,
  TypographyWithLabel
} from '@jeromevvb/equinox-lib'
import useTranslation from '@hooks/useTranslation'
import Round from '@models/projects/enums/round.enum'
import SaleType from '@models/projects/enums/saleType.enum'
import Status from '@models/projects/enums/status.enum'
import Project from '@models/projects/interfaces/project.interface'
import NextLink from 'next/link'
import { useMemo } from 'react'
import { BsCardImage } from 'react-icons/bs'
import styles from './styles'

export interface FeaturedProjectCardProps {
  project: Project
}

const FeaturedProjectCard = ({ project }: FeaturedProjectCardProps) => {
  const { t } = useTranslation('project')

  const {
    slug,
    name,
    shortDescription,
    poolOpenDate,
    participationCloseDate,
    participationOpenDate,
    totalRaise,
    network,
    token,
    media,
    saleType,
    round,
    guild,
    status
  } = project

  const isUpcoming = [Status.UPCOMING].includes(status)
  const isActive = [Status.ACTIVE].includes(status)
  const { logo, feature } = media

  const _isParticipationOpen = useMemo(() => {
    return isParticipationOpen(participationOpenDate, participationCloseDate)
  }, [participationOpenDate, participationCloseDate])

  return (
    <>
      <Title size="large" weight="semibold" className="mb-6 md:mb-10">
        Featured project
      </Title>
      <div key={slug} css={styles} className="project">
        <Card padding={false} className="overflow-hidden">
          <div className="project-bg">
            <div className="project-bg-inner">
              {feature ? (
                <Image alt={name} src={feature.url} />
              ) : (
                <Typography color="muted" className="project-bg-coming-soon">
                  coming soon
                </Typography>
              )}
            </div>
            <div className="project-badges">
              {guild && (
                <ProjectBadge className="project-badge" color="primary">
                  <Tooltip
                    label="Join the guild to play this game"
                    placement="top"
                  >
                    Guild
                  </Tooltip>
                </ProjectBadge>
              )}
              {saleType === SaleType.INO && (
                <ProjectBadge className="project-badge">
                  <Tooltip label="Initial NFT Offering" placement="top">
                    INO
                  </Tooltip>
                </ProjectBadge>
              )}
              {saleType === SaleType.IDO && round !== Round.PRIVATE && (
                <ProjectBadge className="project-badge">
                  <Tooltip label="Initial DEX Offering" placement="top">
                    IDO
                  </Tooltip>
                </ProjectBadge>
              )}
              {round === Round.PRIVATE && (
                <ProjectBadge className="project-badge">
                  <Tooltip
                    label="Private sale. Early stage investment round"
                    placement="top"
                  >
                    Private
                  </Tooltip>
                </ProjectBadge>
              )}
            </div>
          </div>
          <Card className="project-inner">
            <div className="project-header">
              <div className="project-logo">
                {logo ? (
                  <Image alt={name} src={logo.url} />
                ) : (
                  <BsCardImage className="project-logo-placeholder" size="32" />
                )}
              </div>
              <div>
                <Title size="medium" weight="semibold">
                  {name}
                </Title>
                <div className="project-token">
                  <DisplayTokenSymbol
                    TypographyProps={{
                      color: 'muted'
                    }}
                    symbol={saleType === SaleType.INO ? 'NFT' : token?.symbol}
                  />
                </div>
              </div>
              <div className="project-currency">
                <ProjectNetwork network={network} />
              </div>
            </div>

            <Typography size="medium" className="project-description">
              {shortDescription}
            </Typography>
            <Divider className="my-6" horizontal />
            <div className="mb-6 lg:mb-10">
              <TypographyWithLabel label={t('card.totalRaise')}>
                <DisplayCurrency amount={totalRaise} />
              </TypographyWithLabel>

              <TypographyWithLabel label={t('card.launchDate')}>
                <DisplayDate utc date={poolOpenDate} format="dateTime" />
              </TypographyWithLabel>

              <TypographyWithLabel
                label="Sale type"
                TypographyProps={{ size: 'medium' }}
              >
                {saleType === SaleType.INO ? 'NFT Sale' : 'Token Sale'}
              </TypographyWithLabel>

              {isUpcoming && _isParticipationOpen && (
                <TypographyWithLabel label="Status">
                  <StatusIndicator
                    label="Participation open"
                    variant="active"
                  />
                </TypographyWithLabel>
              )}
              {isActive && (
                <TypographyWithLabel label="Status">
                  <StatusIndicator label="Launching now" variant="active" />
                </TypographyWithLabel>
              )}
            </div>
            <NextLink passHref href={`/project/${slug}`}>
              <a>
                <Button
                  size="large"
                  className="mx-auto mb-4"
                  variant="contained"
                >
                  View project
                </Button>
              </a>
            </NextLink>
          </Card>
        </Card>
      </div>
    </>
  )
}

export default FeaturedProjectCard
