import { Title, Button } from '@jeromevvb/equinox-lib'
import NextLink from 'next/link'
import styles from './styles'

const ExplainerBlock = () => (
  <div css={styles}>
    <div className="text">
      <Title size="large">Join the Equinox community.</Title>
      <Title size="large">Get your NFT</Title>
    </div>
    <NextLink href="/how-to-participate" passHref>
      <a className="related-project">
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          className="w-full"
        >
          Read more
        </Button>
      </a>
    </NextLink>
  </div>
)

export default ExplainerBlock
