import tw, { css } from 'twin.macro'

const styles = css`
  .project-bg {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
    ${tw`bg-neutral`}
    ${tw`border-neutral-charcoal`}

    .project-bg-inner > div {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .project-bg-coming-soon {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      ${tw`items-center`}
      ${tw`justify-center`}
      ${tw`bg-neutral-charcoal`}
    }
  }

  .project-inner {
    ${tw`px-4`}
    ${tw`pt-2`}
  }

  .project-header {
    display: flex;
    ${tw`mb-6`}
  }

  .project-logo {
    ${tw`flex-shrink-0`}
    position: relative;
    ${tw`w-24`}
    ${tw`h-24`}
    ${tw`border`}
    ${tw`border-neutral-charcoal`}
    ${tw`rounded-lg`}
    ${tw`bg-neutral-dark`}
    ${tw`-mt-10`}
    ${tw`-ml-1`}
    ${tw`mr-3.5`}
    overflow: hidden;

    ${tw`lg:w-16`}
    ${tw`lg:h-16`}
    ${tw`lg:-ml-2`}
    ${tw`lg:-mt-4`}
    ${tw`lg:mr-2`}

    ${tw`xl:w-24`}
    ${tw`xl:h-24`}
    ${tw`xl:-ml-1`}
    ${tw`xl:-mt-10`}
    ${tw`xl:mr-3.5`}

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .project-logo-placeholder {
    ${tw`text-neutral-charcoal`}
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .project-token {
    display: flex;
    ${tw`-mt-1`}
  }

  .project-currency {
    ${tw`hidden`}
    ${tw`md:flex`}
    flex: 1 1 auto;
    justify-self: flex-end;
    justify-content: flex-end;
    ${tw`h-8`}
  }

  .project-footer {
    text-align: center;
    ${tw`border-t-2`}
    ${tw`border-secondary`}
    ${tw`py-4`}
  }

  .project-status {
    ${tw`bg-secondary`}
    ${tw`text-neutral-dark`}
    ${tw`p-1.5 py-1`}
    ${tw`rounded`}
  }

  .project-badges {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    ${tw`space-x-1`}
  }
`

export default styles
