import dayjs from 'dayjs'

export const isParticipationOpen = (openDate: string, closeDate: string) => {
  if (!openDate) return false

  if (!closeDate) return false

  const now = dayjs().unix()

  if (now > dayjs(closeDate).unix()) {
    return false
  }

  return now > dayjs(openDate).unix()
}
