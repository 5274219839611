import tw, { css, theme } from 'twin.macro'

const styles = () => css`
  ${tw`grid`}
  ${tw`grid-cols-3`}
  ${tw`gap-x-4`}
  ${tw`mb-2`}
  ${tw`mr-8`}
  ${tw`text-center`}
  
  ${tw`bg-neutral-charcoal`}
  ${tw`py-4`}
  ${tw`xs:grid-cols-4`}
  ${tw`lg:mr-0`}

  button.col-header:first-of-type {
    ${tw`col-start-2`}
    ${tw`xs:col-start-3`}
  }
`

export default styles
