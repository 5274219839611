import { Typography } from '@jeromevvb/equinox-lib'
import ColumnHeaderButton from '@components/project/components/LaunchedProjects/ColumnHeaderButton'
import styles from './styles'

interface LaunchedProjectsColumnsProps {
  onSortChange: (sortBy: string) => void
  sortBy: string
  sortAscending: boolean
}

const LaunchedProjectsColumns = ({
  onSortChange,
  sortBy,
  sortAscending
}: LaunchedProjectsColumnsProps) => (
  <div css={styles}>
    <ColumnHeaderButton
      active={sortBy === 'ath'}
      ascending={sortAscending}
      onClick={() => onSortChange('ath')}
    >
      ATH since launch
    </ColumnHeaderButton>
    {/* <div className="col-header"> */}
    {/* <Typography size="small">Current price</Typography> */}
    {/* </div> */}
    <ColumnHeaderButton
      active={sortBy === 'totalRaise'}
      ascending={sortAscending}
      onClick={() => onSortChange('totalRaise')}
    >
      Total raise
    </ColumnHeaderButton>

    <ColumnHeaderButton
      active={sortBy === 'poolOpenDate'}
      ascending={sortAscending}
      onClick={() => onSortChange('poolOpenDate')}
    >
      Launched
    </ColumnHeaderButton>
  </div>
)

export default LaunchedProjectsColumns
