import { Typography } from '@jeromevvb/equinox-lib'
import Project from '@models/projects/interfaces/project.interface'
import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import LaunchedProject from './LaunchedProject'
import LaunchedProjectsColumns from './LaunchedProjectsColumns'
import styles from './styles'

interface LaunchedProjectsProps {
  projects: Array<Project>
  itemsPerPage?: number
  searchQuery: string
}

const LaunchedProjects: React.FC<LaunchedProjectsProps> = ({
  projects,
  itemsPerPage = 10,
  searchQuery
}) => {
  const [sortBy, setSortBy] = useState('poolOpenDate')

  const [sortAscending, setSortAscending] = useState(false)
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [paginatedProjects, setPaginatedProjects] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    if (projects) {
      filterProjects()
    }
  }, [searchQuery])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setPaginatedProjects(filteredProjects.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(filteredProjects.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, filteredProjects])

  useEffect(() => {
    const sortedProjects =
      sortBy === 'poolOpenDate' ? sortByDate() : sortByProperty()
    setPaginatedProjects(sortedProjects.slice(0, itemsPerPage))
    setCurrentPage(0)
    setItemOffset(0)
  }, [sortAscending, sortBy, filteredProjects])

  const filterProjects = () => {
    const filtered = projects.filter((p) => {
      const matchesSearchQuery = p.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
      return matchesSearchQuery
    })
    setFilteredProjects(filtered)
  }

  const sortByProperty = () =>
    filteredProjects.sort((a, b) =>
      sortAscending ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy]
    )

  const sortByDate = () =>
    filteredProjects.sort((a, b) => {
      const dateA = new Date(a.poolOpenDate).getTime()
      const dateB = new Date(b.poolOpenDate).getTime()
      return sortAscending ? dateA - dateB : dateB - dateA
    })

  const handlePageClick = (e) => {
    setCurrentPage(e.selected)
    const newOffset = (e.selected * itemsPerPage) % filteredProjects.length
    setItemOffset(newOffset)
  }

  const handleSortChange = (sort) => {
    if (sort === sortBy) {
      setSortAscending(!sortAscending)
    } else {
      setSortAscending(false)
      setSortBy(sort)
    }
  }

  return (
    <div css={styles}>
      <div className="project-list-wrapper">
        <div className="project-list">
          <LaunchedProjectsColumns
            onSortChange={handleSortChange}
            sortBy={sortBy}
            sortAscending={sortAscending}
          />
          {paginatedProjects.length > 0 &&
            paginatedProjects.map((project, key) => (
              <LaunchedProject project={project} key={key} isLoading={false} />
            ))}
          {paginatedProjects.length === 0 && (
            <Typography color="muted" className="text-center mt-16">
              There are no results to display
            </Typography>
          )}
        </div>
      </div>
      {filteredProjects.length > itemsPerPage && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          forcePage={currentPage}
        />
      )}
    </div>
  )
}

export default LaunchedProjects
