import tw, { css, theme } from 'twin.macro'

const styles = css`
  ${tw`mb-12`}

  .header {
    ${tw`justify-between`}
    ${tw`items-center`}
    ${tw`xl:flex`}
  }

  .filters {
    ${tw`sm:flex`}
    ${tw`sm:justify-between`}
  }

  .search {
    @media (min-width: ${theme`screens.sm`}) {
      width: 13.4rem;
    }

    @media (min-width: ${theme`screens.md`}) {
      width: 14.6rem;
    }
  }

  .pagination {
    display: flex;
    ${tw`justify-center`}
    ${tw`text-neutral-silver`}
    ${tw`text-body-sm`}
    ${tw`select-none`}

    li {
      ${tw`mx-2`}
      ${tw`transition-colors`}
      ${tw`duration-base`}

      &:hover,
      &.selected {
        ${tw`text-secondary`}
      }
    }
  }
`

export default styles
