import { Typography } from '@jeromevvb/equinox-lib'
import getObjectProperty from '@helpers/getObjectProperty'
import Network from '@models/projects/enums/network.enum'
import ProjectNetwork from '@models/projects/enums/projectNetwork.enum'
import Project from '@models/projects/interfaces/project.interface'
import axios, { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useQuery } from 'react-query'
import LaunchedProject from './LaunchedProject'
import LaunchedProjectsColumns from './LaunchedProjectsColumns'
import styles from './styles'

interface LaunchedProjectsProps {
  projects: Array<Project>
  itemsPerPage?: number
  onlyVested: boolean
  searchQuery: string
}

const coinGeckoNetworks = {
  [Network.ETH]: 'ethereum',
  [Network.BSC]: 'binance-smart-chain',
  [Network.POLYGON]: 'polygon-pos',
  [ProjectNetwork.ALGORAND]: 'algorand',
  [ProjectNetwork.SOLANA]: 'solana'
}

const LaunchedProjects: React.FC<LaunchedProjectsProps> = ({
  projects,
  itemsPerPage = 10,
  searchQuery,
  onlyVested
}) => {
  const [sortBy, setSortBy] = useState('ath')
  const [sortAscending, setSortAscending] = useState(false)
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [paginatedProjects, setPaginatedProjects] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    if (fetchedProjects) {
      filterProjects()
    }
  }, [searchQuery, onlyVested])

  // Fetch current price & all-time-high of each project
  const { isLoading, data: fetchedProjects } = useQuery<Project[], AxiosError>(
    'price',
    async () => {
      const fetchedProjects = await projects.map(async (project) => {
        const { network, token, projectNetwork } = project
        const contractAddress = token?.contractAddress
        const price = token?.price

        if (contractAddress && price) {
          const _contractAddress = contractAddress.split(',')[0].toLowerCase()
          const _network = projectNetwork || network
          const id = coinGeckoNetworks[_network]

          try {
            const response = await axios.get(
              `https://api.coingecko.com/api/v3/coins/${id}/contract/${_contractAddress}`
            )

            const current = getObjectProperty(
              response,
              `data.market_data.current_price.usd`
            )

            const ath = getObjectProperty(response, `data.market_data.ath.usd`)
            const athDiff = ath / (Array.isArray(price) ? price[0] : price)
            const currentDiff =
              current / (Array.isArray(price) ? price[0] : price)

            return {
              ...project,
              price: {
                current,
                currentDiff,
                ath,
                athDiff
              }
            }
          } catch (error) {
            return project
          }
        } else {
          return project
        }
      })

      return Promise.all([...fetchedProjects]).then((results) => {
        return results
      })
    },
    {
      staleTime: Infinity,
      onSuccess: (projects) => {
        // Filter by all-time-high once
        // all project prices fetched
        setFilteredProjects(projects)
      }
    }
  )

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setPaginatedProjects(filteredProjects.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(filteredProjects.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, filteredProjects])

  useEffect(() => {
    let sortedProjects =
      sortBy === 'poolOpenDate' ? sortByDate() : sortByProperty()
    if (sortBy === 'ath') sortedProjects = sortByAth()
    setPaginatedProjects(sortedProjects.slice(0, itemsPerPage))
    setCurrentPage(0)
    setItemOffset(0)
  }, [sortAscending, sortBy, filteredProjects])

  const filterProjects = () => {
    const filtered = fetchedProjects.filter((p) => {
      const matchesSearchQuery = p.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())

      let vested = true

      if (onlyVested) {
        vested = getObjectProperty(p, 'vesting.contracts.items', []).length > 0
      }

      return matchesSearchQuery && vested
    })
    setFilteredProjects(filtered)
  }

  const sortByProperty = () =>
    filteredProjects.sort((a, b) =>
      sortAscending ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy]
    )

  const sortByDate = () =>
    filteredProjects.sort((a, b) => {
      const dateA = new Date(a.poolOpenDate).getTime()
      const dateB = new Date(b.poolOpenDate).getTime()
      return sortAscending ? dateA - dateB : dateB - dateA
    })

  const sortByAth = () =>
    filteredProjects.sort((a, b) => {
      const athDiffA = a.price ? a.price.athDiff : 0
      const athDiffB = b.price ? b.price.athDiff : 0
      return sortAscending ? athDiffA - athDiffB : athDiffB - athDiffA
    })

  const handlePageClick = (e) => {
    setCurrentPage(e.selected)
    const newOffset = (e.selected * itemsPerPage) % filteredProjects.length
    setItemOffset(newOffset)
  }

  const handleSortChange = (sort) => {
    if (sort === sortBy) {
      setSortAscending(!sortAscending)
    } else {
      setSortAscending(false)
      setSortBy(sort)
    }
  }

  return (
    <div css={styles}>
      <div className="project-list-wrapper">
        <div className="project-list">
          <LaunchedProjectsColumns
            onSortChange={handleSortChange}
            sortBy={sortBy}
            sortAscending={sortAscending}
          />
          {paginatedProjects.length > 0 &&
            paginatedProjects.map((project, key) => (
              <LaunchedProject
                project={project}
                key={key}
                isLoading={isLoading}
              />
            ))}
          {paginatedProjects.length === 0 && (
            <Typography color="muted" className="text-center mt-16">
              There are no results to display
            </Typography>
          )}
        </div>
      </div>
      {filteredProjects.length > itemsPerPage && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="Previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          forcePage={currentPage}
        />
      )}
    </div>
  )
}

export default LaunchedProjects
