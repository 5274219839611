import tw, { css } from 'twin.macro'

const styles = () => css`
  position: relative;
  display: flex;
  ${tw`items-center`}
  ${tw`justify-center`}

    p {
    display: inline-block;
    ${tw`text-body-sm`}
    ${tw`text-secondary`}
  }

  .col-header-chevron {
    display: none;
  }

  &.active {
    .col-header-chevron {
      ${tw`ml-2.5`}
      transform: rotate(90deg);
      display: block;
    }

    &.ascending {
      .col-header-chevron {
        transform: rotate(-90deg);
      }
    }
  }
`

export default styles
