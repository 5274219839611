import ExplainerBlock from '@components/common/ExplainerBlock'
import Page from '@components/common/Page'
import FeaturedProjectCard from '@components/project/components/FeaturedProjectCard'
import LaunchedProjects from '@components/project/components/LaunchedProjects'
import ProjectsList from '@components/project/components/ProjectsList'
import useProjects from '@contexts/ProjectsContext/hooks/useProjects'
import Status from '@models/projects/enums/status.enum'

const Home = () => {
  return (
    <Page
      title="Equinox - NOX Ecosystem"
      description="Equinox is a fully functional, harmonious digital ecosystem of decentralized applications and value-add services, comprising an NFT-based IDO Launchpad and a tokenized Marketplace and Gaming Guild."
    >
      <Projects />
    </Page>
  )
}

const Projects = () => {
  const { projects, featuredProject } = useProjects()
  const { ended } = projects

  const featuredProjectEnded = featuredProject
    ? [Status.ENDED].includes(featuredProject.status)
    : true

  return (
    <div className="container">
      {/* This banner is temporary */}
      {/* <div className="bg-primary py-6 px-4 rounded-lg mb-10">
        <Title>Equinox has it's own marketplace (beta version)</Title>

        <div className="mt-5">
          <Typography size="small">
            If you own an Equinox NFT, please proceed to exchange it now.
          </Typography>
          <div className="mt-3"></div>

          <Typography size="small">
            If you are a Liquidifty holder,{' '}
            <Link href="/exchange" size="small" underline>
              swap your NFT
            </Link>
          </Typography>

          <Typography size="small">
            If you are a Gifted holder,{' '}
            <Link size="small" href="/exchange-gifted" underline>
              claim your tradable NFT
            </Link>
          </Typography>
          <div className="mt-3"></div>

          <Typography size="small">
            For further information, please{' '}
            <Link
              size="small"
              external
              href="https://equinox-launch.medium.com/equinoxs-nft-swap-portal-is-about-to-open-f280e1241f1e"
              underline
            >
              read the article
            </Link>
          </Typography>
        </div>
      </div> */}

      {featuredProject && !featuredProjectEnded && (
        <FeaturedProjectCard project={featuredProject} />
      )}
      <ProjectsList />
      {ended.length > 0 && <LaunchedProjects projects={projects.ended} />}
      <ExplainerBlock />
    </div>
  )
}

export default Home
