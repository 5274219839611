import tw, { css } from 'twin.macro'

const minWidth = '640px'

const styles = css`
  .project-list-wrapper {
    ${tw`overflow-x-scroll`}
    ${tw`-mr-4`}
    ${tw`mb-4`}

    ${tw`xxs:-mr-6`}
    ${tw`xs:-mr-8`}
    ${tw`lg:-mr-0`}
    
    @media (min-width: ${minWidth}) {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 0.2rem;
      ${tw`h-2`}
      ${tw`bg-neutral`}
      ${tw`rounded-md`}
      ${tw`p-1`}
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      ${tw`bg-neutral-silver`}
      ${tw`rounded-md`}
    }
  }

  .project-list {
    ${tw`mb-6`}
    min-width: ${minWidth};
  }

  .project {
    display: grid;
    ${tw`grid-cols-3`}
    ${tw`items-center`}
    ${tw`py-4`}
    ${tw`mr-8`}
    cursor: pointer;
    ${tw`gap-x-4`}
    ${tw`border-b`}
    ${tw`border-neutral-charcoal`}
    ${tw`xs:grid-cols-4`}
    ${tw`lg:mr-0`}

    &:hover {
      ${tw`bg-neutral-charcoal`}
    }
  }

  .project-title {
    display: flex;
    ${tw`items-center`}
    ${tw`pl-4`}
    
    ${tw`xs:col-span-2`}
    ${tw`md:pr-4`}
  }

  .project-image {
    ${tw`w-12`}
    ${tw`h-12`}
    ${tw`mr-2`}
    ${tw`border-2`}
    ${tw`border-neutral-charcoal`}
    ${tw`rounded-lg`}
    overflow: hidden;
    ${tw`flex-shrink-0`}

    ${tw`md:w-16`}
    ${tw`md:h-16`}
    ${tw`md:mr-4`}
  }
`

export default styles
