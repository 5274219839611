import tw, { css, theme } from 'twin.macro'

const styles = css`
  ${tw`self-stretch`}

  .card {
    height: 100%;
    ${tw`flex`}
    ${tw`justify-center`}
    ${tw`items-center`}
    ${tw`text-center`}
    ${tw`py-16`}
    ${tw`xl:px-12`}
  }

  .copy {
    background: linear-gradient(45deg, #6560bd 0%, #69c0ca 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${tw`mb-6`}
    max-width: 30rem;
  }
`

export default styles
